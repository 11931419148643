/* eslint-disable react/require-default-props */
import React from 'react';
import styled from 'styled-components';
import { Grid, Button } from 'semantic-ui-react';
import cx from 'classnames';
import _ from 'lodash';

import ReactMarkdown from 'react-markdown';

import { getNewsSlug } from '../../lib/utils';


export const NewsCard1 = props => {
  const { cat } = props;
  const locPath = getNewsSlug(cat);
  return (
    <div className={cx(props.className, _.get(cat, 'catMeta.className'))}>
      <Grid className="content">
        <Grid.Column className="header" mobile={16} tablet={16} computer={16}>
          <div className="tags">
            {_.castArray(_.get(cat, 'tags', [])).map(tag => {
              return (<div className="tag text-small" key={tag}>{tag}</div>)
            })}
          </div>
          <div className="title text-h4">
            {_.get(cat, 'title', 'title')}
          </div>
          <div className="sub-title text-p">
            <ReactMarkdown source={_.get(cat, 'description', 'description')} />
          </div>
          <Button
            className="button-more"
            as="a"
            href={locPath}
            color={"pink"}
          >Read More</Button>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default styled(NewsCard1)`
  border: 1px solid #707070;
  border-radius: 20px;
  .content {
    padding: 20px 10px;
    font-weight: normal;
    .button-more {
      padding: 6px 20px;
      font-weight: 400;
      display: inline-block;
    }
    .header {
      margin-bottom: 20px;
      .button-more {
        @media (max-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .details {
      .button-more {
        @media (min-width: ${({ theme }) => theme.notDesktop}) {
          display: none;
        }  
      }
    }
    .title {
      font-weight: 700;
    }
    .sub-title {
      line-height: 24px;
    }
    .tags {
      .tag {
        display: inline-block;
        color: ${props => props.theme.txtPink};
        text-decoration: underline;
        &:not(:first-child) {
          &:before {
            content: '|';
            margin: 0 2px;
            color: ${props => props.theme.txtPink};
            text-decoration: none;
          }
        }
      }
    }
    .desc-container {
      .label {
        font-weight: 700;
        margin-bottom: 0px;
      }
      .desc {
      }
      margin-bottom: 10px;
    }
  }
  a {
    color: ${props => props.theme.txtTeal};
    text-decoration: underline;
  }
  height: 100%;
  &.type-1 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtOrange};
    }
  }
  &.type-2 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtTeal};
    }
  }
  &.type-3 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtYellow};
    }
  }
  &.type-4 {
    .content {
    }
    .title, .tos, .sub-title-2, .tag {
      color: ${props => props.theme.txtPink};
    }
  }
`;
